<template>
  <div class="start">
    <div class="container right-panel-active">
        <div class="container__form container--signup">
          <form action="#" class="form" id="form1">
            <h2 class="form__title">注册</h2>
            
            <input type="email" placeholder="Email" class="input" v-model="email" @blur="ValiEmail" autocomplete="off"/>
            <input type="password" placeholder="Password" class="input" v-model="password" autocomplete="off"/>
              <input type="text" placeholder="ValiCode" class="input" v-model="valicode"/><el-button size="mini" @click="GetValiCode">获取验证码</el-button>
            <el-button class="btn" @click="RegisterIng">注册</el-button>
          </form>
        </div>
        <div class="container__form container--signin">
          <form action="#" class="form" id="form2">
            <h2 class="form__title">登录</h2>
            <input type="email" placeholder="Email" class="input" v-model="email" autocomplete="off" @blur="ValiEmail"/>
            <input type="password" placeholder="Password" class="input" v-model="password" autocomplete="off"/>
            <a href="#" class="link" @click="ToReWritePd">Forgot your password?</a>
            <el-button class="btn" @click="LoginIn">登录</el-button>
          </form>
        </div>
        <div class="container__overlay">
          <div class="overlay">
            <div class="overlay__panel overlay--left">
              <button class="btn" id="signIn" @click="SignInMark">登录</button>
            </div>
            <div class="overlay__panel overlay--right">
              <button class="btn" id="signUp" @click="SignUpMark">注册</button>
            </div>
          </div>
        </div>
        <div class="ReWritePdBox">
          <div class="ReturnLoginPanel">
            <div class="ReturnBtn" @click="ReturnLoginPanel">
              <i class="iconfont icon-fanhui"></i>
            </div>
            修改密码
          </div>
          <div class="ReWriteInnerBox">
            <div class="EmailArea">
              <el-input v-model="ReEmail" placeholder="请输入邮箱账号"></el-input>
            </div>
            <div class="PassWordArea">
              <el-input v-model="RePassWord" placeholder="请输入新密码"></el-input>
            </div>
            <div class="ValiArea">
              <div class="ReValiBox">
                <el-input v-model="ReValiCode" placeholder="请输入验证码"></el-input>
              </div>
              <div class="SendReValiCodeBtn" @click="GetReWriteInfoCode">获取验证码</div>
            </div>
            <div class="ReWriteComfirmBtnArea">
              <div class="ReWriteComfirmBtn" @click="ComfirmReWrite">确认修改</div>
            </div>
          </div>
        </div>
    </div>
    <div class="CopyRight"><a href="https://beian.miit.gov.cn/" target="_blank"> 浙ICP备2021023350号</a></div>
  </div>
</template>

<script>
import {Login , Register , GetReValiCode ,ReWritePass} from "@/api/index.js"
import '../assets/rem.js'
export default {
  name: 'Start',
  data(){
    return{
        username:'',
        password:'',
        email:'',
        valicode:'',


        ReEmail:'',
        RePassWord:'',
        ReValiCode:''
    }
  },
  methods:{
    SignInMark(){
      const container = document.querySelector(".container")
      container.classList.remove("right-panel-active")
    },
    SignUpMark(){
      const container = document.querySelector(".container")
      container.classList.add("right-panel-active")
    },
    LoginIn($event){
      $event.preventDefault()
      if(this.email&&this.password){
        let LoginData = {
          "email":this.email,
          "password":this.password
        }
        Login(LoginData).then(res=>{
          if(res.code == 0){
            window.localStorage.setItem("token",res.data)
            this.$notify({title: '登录成功',message: '开始你的开卷计划',type: 'success'});
            this.$router.replace('/index')
          }
        })
      }else{
        alert('请输入合法字段以进行操作  ')
      }
    },
    RegisterIng(){
        if(this.email&&this.password&&this.valicode){
          let RegisterData = {
            "email":this.email,
            "password":this.password,
            "validCode":this.valicode
          }
          Register(RegisterData).then(res=>{
            if(res.code == 0){
              this.$notify({title: '注册成功',message: '可进行登录',type: 'success'});
              this.SignInMark()
            }
          })
        }
    },
    ValiEmail(){
      let valiEmail = this.email
      const reg = /^([a-zA-Z0-9])+(([a-zA-Z0-9])|([._-][a-zA-Z0-9])*)+@([a-zA-Z0-9-])+((\.[a-zA-Z0-9-]{2,3}){1,2})$/
      let valiData =  reg.test(valiEmail)
      if(valiData){
          return false
      }else{
          alert('请输入合法的邮箱地址')
      }
    },
    GetValiCode(){
      if(this.email){
        this.$http.post('/v1/register_code',{"email":this.email}).then(res=>{
          if(res.status == 200){
            this.$message({ message: '请检查邮箱验证码',type: 'success'});
          }
        })
      }else{
        alert('请输入邮箱')
      }
      
    },
    ToReWritePd(){
      $(".ReWritePdBox").animate({
        "left":"0"
      },400)
    },
    ReturnLoginPanel(){
      this.ReEmail = ''
      this.RePassWord = ''
      this.ReValiCode = ''
      $(".ReWritePdBox").animate({
        "left":"-100%"
      },400)
    },
    GetReWriteInfoCode(){
      if(this.ReEmail && this.RePassWord){
        let Info = {
          "email": this.ReEmail,
          "password": this.RePassWord
        }
        GetReValiCode(Info).then(res=>{
          this.$message({ message: '验证码下发成功，注意邮箱接收！',type: 'success'});
        })
      }else{
        this.$message({ message: '请填写完整信息',type: 'error'});
      }
    },
    ComfirmReWrite(){
      if(this.ReEmail && this.RePassWord && this.ReValiCode){
        let Info = {
          "email": this.ReEmail,
          "password": this.RePassWord,
          "validCode":this.ReValiCode
        }
        ReWritePass(Info).then(res=>{
          this.$message({ message: '密码修改成功！',type: 'success'});
          this.ReturnLoginPanel()
        })
      }else{
        this.$message({ message: '请填写完整信息',type: 'error'});
      }
    }
  }
}

</script>
<style scoped>
.start{
  position: relative;
    align-items: center;
    background-color:#e9e9e9;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: 100vh;
    place-items: center;
}
.form__title {
      font-weight: 300;
      margin: 0;
      margin-bottom: 0.032rem;
      font-size: 0.34rem;
    }

    .link {
      color: #333;
      font-size: 0.144rem;
      margin: 0.24rem 0;
      text-decoration: none;
    }
.container {
      background-color: #e9e9e9;
      border-radius: 0.112rem;
      box-shadow: 0 0.144rem 0.272rem rgba(0, 0, 0, 0.25),
        0 0.112rem 0.7rem rgba(0, 0, 0, 0.22);
      height:  420px;
      max-width: 758px;
      overflow: hidden;
      position: relative;
      width: 100%;
}
.container__form {
      height: 100%;
      position: absolute;
      top: 0;
      transition: all 0.6s ease-in-out;
    }
.container--signin {
      left: 0;
      width: 50%;
      z-index: 2;
}
.container.right-panel-active .container--signin {
      transform: translateX(100%);
    }
.container--signup {
      left: 0;
      opacity: 0;
      width: 50%;
      z-index: 1;
    }
.container.right-panel-active .container--signup {
      animation: show 0.6s;
      opacity: 1;
      transform: translateX(100%);
      z-index: 5;
    }
.btn {
      background-color: #0367a6;
      background-image: linear-gradient(90deg, #0367a6 0%, #008997 74%);
      border-radius: 20px;
      border: 1px solid #0367a6;
      color: #e9e9e9;
      cursor: pointer;
      font-size: 0.128rem;
      font-weight: bold;
      letter-spacing: 0.016rem;
      padding: 0.144rem 0.64rem;
      text-transform: uppercase;
      transition: transform 80ms ease-in;
    }
.form>.btn {
      margin-top: 0.24rem;
    }
.btn:active {
      transform: scale(0.95);
    }

    .btn:focus {
      outline: none;
    }

    .form {
      background-color: #e9e9e9;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 0.48rem;
      height: 100%;
      text-align: center;
    }
.input {
      background-color: #fff;
      border: none;
      padding: 0.144rem 0.144rem;
      margin: 0.08rem 0;
      width: 100%;
    }
.container__overlay {
      height: 100%;
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 0;
      transition: transform 0.6s ease-in-out;
      width: 50%;
      z-index: 100;
    }
.container.right-panel-active .container__overlay {
      transform: translateX(-100%);
    }

    .overlay {
      background-color: #008997;
      /* background: url("https://res.cloudinary.com/dbhnlktrv/image/upload/v1599997626/background_oeuhe7.jpg"); */
      background-attachment: fixed;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      left: -100%;
      position: relative;
      transform: translateX(0);
      transition: transform 0.6s ease-in-out;
      width: 200%;
    }
.container.right-panel-active .overlay {
      transform: translateX(50%);
    }

    .overlay__panel {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      position: absolute;
      text-align: center;
      top: 0;
      transform: translateX(0);
      transition: transform 0.6s ease-in-out;
      width: 50%;
    }
.overlay--left {
      transform: translateX(-20%);
    }

    .container.right-panel-active .overlay--left {
      transform: translateX(0);
    }

    .overlay--right {
      right: 0;
      transform: translateX(0);
    }

    .container.right-panel-active .overlay--right {
      transform: translateX(20%);
    }
  .GetValiCode{
    cursor: pointer;
  }
  .CopyRight{
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 14px;
    color: black;
  }
.ReWritePdBox{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  z-index: 100;
  background-color: #008997;
  box-sizing: border-box;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ReturnLoginPanel{
  width: 100%;
  height: 0.3rem;
  font-size: 0.16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.ReturnBtn{
  position: absolute;
  left: 0.1rem;
  width: 0.4rem;
  height: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.ReWriteInnerBox{
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 0.2rem 0.4rem;
}
.EmailArea{
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}
.PassWordArea{
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}
.ValiArea{
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
}
.ReValiBox{
  flex: 1;
  display: flex;
  align-items: center;
}
.SendReValiCodeBtn{
  width: 1rem;
  height: 0.4rem;
  margin-left: 0.1rem;
  font-size: 0.16rem;
  background-color: white;
  border-radius: 0.04rem;
  text-align: center;
  line-height: 0.4rem;
  box-sizing: border-box;
  padding: 0 0.1rem;
  cursor: pointer;
}
.ReWriteComfirmBtnArea{
  width: 100%;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ReWriteComfirmBtn{
  width: 1rem;
  height: 0.4rem;
  box-sizing: border-box;
  padding: 0 0.1rem;
  font-size: 0.16rem;
  background-color: white;
  border-radius: 0.04rem;
  text-align: center;
  line-height: 0.4rem;
  cursor: pointer;
}
</style>